import { Box, Button, Grid, Typography } from '@mui/material';
import { Campaign, Setting, UserCampaign } from '@prisma/client';
import { sortBy, uniqBy } from 'lodash';
import NextLink from 'next/link';
import React, { useState } from 'react';

import { CampaignCard } from '~/components/Campaign/CampaignCard';
import { Layout } from '~/components/Meta/Layout';
import { Unauthorized } from '~/components/Meta/Unauthorized';
import { SettingCard } from '~/components/Setting/SettingCard';
import { Tabs } from '~/components/Tabs';
import { IMAGES } from '~/constants/images';
import {
  buildCampaignDetailRoute,
  buildSettingsDetailRoute,
  CREATE,
} from '~/constants/routes';
import { getServerSidePropsWrapper } from '~/libs/getServerSideProps/getServerSidePropsWrapper';
import prisma from '~/libs/prisma';
import { UserCampagnWithUser } from '~/typings/complexPrisma';

interface CampaignsWithUsersAndSetting extends Campaign {
  user_campaigns: UserCampagnWithUser[];
  setting: Setting;
}

interface HomePageProps {
  campaigns: CampaignsWithUsersAndSetting[];
  authorized?: boolean;
}

interface CampaignsPageProps {
  campaigns: CampaignsWithUsersAndSetting[];
}

interface SettingsListProps {
  settings: Setting[];
}

function CampaignList({ campaigns }: CampaignsPageProps) {
  if (!campaigns.length) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Typography variant="h6">No Campaigns Found</Typography>
      </Box>
    );
  }

  return (
    <>
      {campaigns.map((campaign) => (
        <CampaignCard
          campaign={campaign}
          campaignUsers={campaign.user_campaigns.map((uc) => uc.user)}
          key={campaign.campaign_id}
        />
      ))}
    </>
  );
}

function SettingsList({ settings }: SettingsListProps) {
  if (!settings.length) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Typography variant="h6">
          None of your campaigns have associated settings. Add one to an
          existing campaign or create one here. You must associate a new setting
          with an existing campaign.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {settings.map((setting) => (
        <SettingCard key={setting.setting_id} setting={setting} />
      ))}
    </>
  );
}

function Campaigns({ campaigns }: CampaignsPageProps) {
  const settings = uniqBy(
    campaigns.map((c) => c.setting).filter(Boolean),
    'setting_id'
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  return (
    <Layout
      meta="Campaigns and Settings"
      navBanner={{
        title: 'My Campaigns and Settings',
        imageAlt: IMAGES.campaignsIndex.alt,
        imageSrc: IMAGES.campaignsIndex.src,
        objectPosition: '0% 76%',
      }}
      title="My Campaigns and Settings"
    >
      <Tabs
        activeTabIndex={activeTabIndex}
        aria-label="Home Page Tab"
        tabLabels={['Campaigns', 'Settings']}
        onChange={setActiveTabIndex}
      />
      {activeTabIndex === 0 ? (
        <>
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <NextLink href={buildCampaignDetailRoute(CREATE)}>
              <Button variant="contained">Create a Campaign</Button>
            </NextLink>
          </Box>
          <Grid
            component="ul"
            container
            spacing={2}
            sx={{ listStyle: 'none', padding: 0 }}
          >
            <CampaignList campaigns={campaigns} />
          </Grid>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <NextLink href={buildSettingsDetailRoute(CREATE)}>
              <Button variant="contained">Create a Setting</Button>
            </NextLink>
          </Box>
          <Grid
            component="ul"
            container
            spacing={2}
            sx={{ listStyle: 'none', padding: 0 }}
          >
            <SettingsList settings={settings} />
          </Grid>
        </>
      )}
    </Layout>
  );
}

export function HomePage({ campaigns, authorized }: HomePageProps) {
  if (!authorized) {
    return (
      <Layout meta="" title="BoneWorld Quest">
        <Unauthorized />
      </Layout>
    );
  }
  return <Campaigns campaigns={campaigns} />;
}

export const getServerSideProps = getServerSidePropsWrapper<CampaignsPageProps>(
  async (_, { session }) => {
    if (!session?.user) {
      return {
        props: {
          authorized: false,
          campaigns: [],
        },
      };
    }
    let campaigns: (CampaignsWithUsersAndSetting & {
      user_campaigns: UserCampaign[];
    })[] = [];
    try {
      campaigns = (await prisma.campaign.findMany({
        where: {
          user_campaigns: {
            some: {
              user_id: session?.user.id,
            },
          },
        },
        include: {
          user_campaigns: {
            include: {
              user: true,
            },
          },
          setting: true,
        },
      })) as (CampaignsWithUsersAndSetting & {
        user_campaigns: UserCampaign[];
      })[];
    } catch (e) {
      console.error(e);
    }

    campaigns = sortBy(campaigns, [
      (c) =>
        -new Date(
          c.user_campaigns.find((uc) => uc.user_id === session?.user?.id)
            ?.last_visited_on || 0
        ).getTime(),
      (c) => -new Date(c.created_on).getTime(),
    ]);

    return {
      props: {
        campaigns,
        authorized: true,
      },
    };
  }
);

export default HomePage;
