const TABS_ID = 'zzz-tab';

export const buildTabId = (id: string | number) => `${TABS_ID}-${id}`;
export const buildTabPanelId = (id: string | number) => `${TABS_ID}panel-${id}`;

export function buildTabA11yProps(id: string | number) {
  return {
    id: buildTabId(id),
    'aria-controls': buildTabPanelId(id),
  };
}

export const getTabIndex = (
  tabs: string[] | readonly string[],
  queryTab?: string
) => {
  if (!queryTab) {
    return 0;
  }

  const index = tabs.findIndex(
    (tab) => tab.toLowerCase() === queryTab.toLowerCase()
  );

  return index > -1 ? index : 0;
};
