import { Tab, Tabs as MuiTabs } from '@mui/material';
import type * as CSS from 'csstype';
import { ComponentProps, useState } from 'react';

import { buildTabA11yProps } from '~/libs/tabs';

interface TabsProps {
  tabLabels: string[] | readonly string[];
  defaultTabIndex?: number;
  onChange?: (index: number) => void;
  'aria-label': string;
  scrollable?: boolean;
  activeTabIndex?: number;
  orientation?: ComponentProps<typeof MuiTabs>['orientation'];
  className?: string;
  tabTextTransform?: CSS.Properties['textDecoration'];
}

export function Tabs({
  tabLabels,
  defaultTabIndex = 0,
  onChange,
  'aria-label': ariaLabel,
  scrollable = true,
  activeTabIndex,
  orientation,
  className,
  tabTextTransform,
}: TabsProps) {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const onTabChange: ComponentProps<typeof MuiTabs>['onChange'] = (_, i) => {
    // If we aren't using controlled tabs, update the state
    if (activeTabIndex === undefined) {
      setTabIndex(i as number);
    }
    onChange?.(i as number);
  };

  return (
    <MuiTabs
      aria-label={ariaLabel}
      className={className}
      defaultValue={defaultTabIndex}
      orientation={orientation}
      scrollButtons={scrollable ? 'auto' : undefined}
      value={activeTabIndex ?? tabIndex}
      variant={scrollable ? 'scrollable' : undefined}
      onChange={onTabChange}
    >
      {tabLabels.map((label: string, index: number) => (
        <Tab
          key={label}
          label={label}
          {...buildTabA11yProps(index)}
          sx={{
            textAlign: orientation === 'vertical' ? 'start' : 'center',
            alignItems: orientation === 'vertical' ? 'start' : 'center',
            textTransform: tabTextTransform || 'uppercase',
          }}
        />
      ))}
    </MuiTabs>
  );
}
