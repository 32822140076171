import {
  AvatarGroup,
  Box,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { Campaign, User } from '@prisma/client';

import { Link } from '~/components/Link';
import { buildCampaignDetailRoute } from '~/constants/routes';

import { Avatar } from '../Users/Avatar';

interface CampaignCardProps {
  campaign: Campaign;
  campaignUsers: User[];
}

const CampaignLink = styled(Link)`
  width: 100%;
  :hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const Group = styled(AvatarGroup)`
  justify-content: flex-end;
`;

const Card = styled(MuiCard)`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto auto;
`;

export function CampaignCard({ campaign, campaignUsers }: CampaignCardProps) {
  return (
    <Grid component="li" item key={campaign.campaign_id} md={4} sm={6} xs={12}>
      <Card variant="outlined">
        <CampaignLink
          href={buildCampaignDetailRoute(campaign.campaign_id)}
          underline={false}
          useCustomHover
        >
          <CardHeader title={campaign.title} />
          {campaign.image && (
            <CardMedia
              alt="campaign image"
              component="img"
              height="140"
              image={campaign.image}
            />
          )}
          <CardContent style={campaign.image ? undefined : { paddingTop: 0 }}>
            <Typography variant="caption">{campaign.description}</Typography>
          </CardContent>
        </CampaignLink>
        <Divider />
        <Box px={2} py={1}>
          <Typography variant="caption">Active Players:</Typography>
          <Group max={5}>
            {campaignUsers.map((user) => (
              <Avatar
                alt={user.name || 'Unknown User'}
                key={user.id}
                letters={user.name || 'UU'}
                src={user.image}
              />
            ))}
          </Group>
        </Box>
      </Card>
    </Grid>
  );
}
